<!--Created by 曾伟城 on 2021/1/26.-->
<template>
  <div>
    <!-- 新建战区 -->
    <el-dialog :visible.sync="addWardZone" center width="80%" class="addLegion_drawer">
      <p class="view_title">添加战区</p>
      <div class="select_wrap mt10">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addWard">添加战区</el-button>
        <div>
          <el-input class="ml10" clearable placeholder="请输入战区名称" v-model="wardInfo.generalName"></el-input>
        </div>
        <el-button type="primary" class="ml10" icon="el-icon-search" @click="handleSearchWard">搜索</el-button>
        <el-button type="primary" class="ml10" icon="el-icon-link" @click="handleExportWard">导出</el-button>
      </div>
      <el-table :data="wardList" border stripe style="width: 100%">
        <el-table-column prop="name" label="战区名称"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="remark" label="备注信息"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-tag type="primary" @click="handleEditWard(scope.row)">编辑</el-tag>
            <el-tag type="success" @click="handleSaveLeagle(scope.row)">绑定军团</el-tag>
            <el-tag type="danger" @click="handleDeleteWard(scope.row.id)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-dialog>
    <!-- 添加战区 -->
    <el-dialog title="新增战区" :visible.sync="addWardVisible" width="40%">
      <el-form :model="wardForm" label-width="100px">
        <el-form-item label="战区名称" required>
          <el-input v-model="wardForm.name" placeholder="请输入战区名称" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序值" required>
          <el-input v-model="wardForm.sort" placeholder="请输入排序值，数字越大排在越前"></el-input>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input type="textarea" :rows="3" v-model="wardForm.remark" maxlength="100" show-word-limit placeholder="备注信息"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addWardVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 军团列表 -->
    <el-dialog :visible.sync="showLegionVisible" center width="60%" class="addLegion_drawer">
      <p class="view_title">军团列表</p>
      <div class="select_wrap mt10">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="selectLegion">选择军团</el-button>
        <div>
          <span>请输入搜索内容:</span>
          <el-input class="ml10" clearable placeholder="请输入军团ID" v-model="legionInfo.categoryId"></el-input>
          <el-input class="ml10" clearable placeholder="请输入军团名称" v-model="legionInfo.categoryName"></el-input>
        </div>
        <el-button type="primary" class="ml10" icon="el-icon-search" @click="handleSearchLegion">搜索</el-button>
      </div>
      <el-table :data="legionList" border stripe style="width: 100%">
        <el-table-column prop="id" label="军团ID"></el-table-column>
        <el-table-column prop="name" label="军团名称"></el-table-column>
        <el-table-column prop="brandCount" label="品牌商数量"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" fixed="right" width="250">
          <template slot-scope="scope">
            <el-tag type="danger" @click="handleDeleteWardCat(scope.row.id)">移除</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange2"
          :current-page="legionPage"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="legionTotal"
        ></el-pagination>
      </div>
    </el-dialog>
    <!-- 选择军团 -->
    <el-dialog :visible.sync="selectLegionVisible" center width="50%" class="addLegion_drawer">
      <p class="view_title">选择军团</p>
      <el-alert title="注意：下方列表仅显示没有归属战区的军团" type="warning" :closable="false"></el-alert>
      <div class="select_wrap mt10">
        <div>
          <span>请输入搜索内容:</span>
          <el-input class="ml10" clearable placeholder="请输入军团ID" v-model="selectLegionInfo.categoryId"></el-input>
          <el-input class="ml10" clearable placeholder="请输入军团名称" v-model="selectLegionInfo.categoryName"></el-input>
        </div>
        <el-button type="primary" class="ml10" icon="el-icon-search" @click="handleSearchSelectLegion">搜索</el-button>
      </div>
      <el-table class="brandTable" :data="selectLegionList" border stripe style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column prop="id" label="军团ID"></el-table-column>
        <el-table-column prop="name" label="军团名称"></el-table-column>
        <el-table-column prop="brandCount" label="品牌商数量"></el-table-column>
      </el-table>
      <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange3"
          :current-page="selectlegionPage"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="selectlegionTotal"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectLegionVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveLegion">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
export default {
  name: "addWard",
  data() {
    return {
      addWardZone: false,
      addWardVisible: false,
      showLegionVisible: false,
      selectLegionVisible: false,
      wardInfo: {},
      legionInfo: {},
      selectLegionInfo: {},
      wardForm: {},
      wardId: '',
      wardList: [],
      legionList: [],
      selectLegionList: [],
      total: 0,
      legionTotal: 0,
      selectlegionTotal: 0,
      size: 10,
      page: 1,
      legionPage: 1,
      selectlegionPage: 1,
      multipleSelection: [],
      activityID: ''
    }
  },
  watch: {
    addWardZone: {
      handler(val) {
        if (val) {
          this.page = 1
          this.getList()
        }
      },
      immediate: true
    },
    showLegionVisible: {
      handler(val) {
        if (val) {
          this.legionPage = 1
          this.legionList = []
          this.getBrandCategoryList(this.wardId)
        }
      },
      immediate: true
    },
    selectLegionVisible: {
      handler(val) {
        if (val) {
          this.selectlegionPage = 1
          this.selectLegionList = []
          this.getBrandCategoryList(0)
        }
      },
      immediate: true
    }
  },
  methods: {
    //获取军团列表
    async getBrandCategoryList (id) {
      let params = {
        actId: this.activityID,
        page: id ? this.legionPage: this.selectlegionPage,
        size: 10,
        generalId: id,
      }
      params.categoryId = id ? this.legionInfo.categoryId : this.selectLegionInfo.categoryId
      params.categoryName = id ? this.legionInfo.categoryName : this.selectLegionInfo.categoryName
      try {
        const {data: { list, total }} = await ActivityAjax.getBrandCategoryList(params);
        if (id) {
          this.legionList = list;
          this.legionTotal = total;
        } else {
          this.selectLegionList = list;
          this.selectlegionTotal = total;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取战区列表
    async getList () {
      let params = {
        ...this.wardInfo,
        page: this.page,
        size: this.size,
        actId: this.activityID
      }
      try {
        const {data: { list, total }} = await ActivityAjax.postListBrandGeneral(params);
        this.wardList = list;
        this.total = total;
        if (this.wardList.length === 0 && this.page > 1) {
          this.page--
          this.getList()
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 保存添加军团
    async handleSaveLegion () {
      let params = {
        brandGeneralId: this.wardId,
        ids: this.multipleSelection.map(i => i.id)
      }
      try {
        const res = await ActivityAjax.postAddBrandCat(params);
        if (res.code === 0) {
          this.selectLegionVisible = false
          this.getBrandCategoryList(this.wardId)
          this.$emit('refreshList')
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
    selectLegion () {
      this.selectLegionVisible = true
    },
    handleSearchWard () {
      this.page = 1
      this.getList()
    },
    handleSearchLegion () {
      this.legionPage = 1
      this.getBrandCategoryList(this.wardId)
    },
    handleSearchSelectLegion () {
      this.selectlegionPage = 1
      this.getBrandCategoryList(0)
    },
    async handleExportWard () {
      let params = {
        ...this.wardInfo,
        actId: this.activityID
      }
      try {
        const data = await ActivityAjax.postExportBrandGeneral(params);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "战区列表.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        this.$message.error(e)
      }
    },
    handleEditWard (item) {
      this.wardForm = {...item}
      this.addWardVisible = true
    },
    handleSaveLeagle (item) {
      this.wardId = item.id
      this.showLegionVisible = true
    },
    // 删除战区
    handleDeleteWard (id) {
      this.$confirm("是否删除此战区？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const res = await ActivityAjax.deleteBrandGeneral(id);
          if (res.code === 0) {
            this.$message.success('删除成功')
            this.getList()
            this.$emit('refreshList')
          }
        } catch (e) {
          this.$message.error(e)
        }
      })
    },
    // 删除选中战区
    handleDeleteWardCat (id) {
      this.$confirm("是否移除此战区？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const res = await ActivityAjax.deleteBrandGeneralCat(id);
          if (res.code === 0) {
            this.$message.success('移除成功')
            this.getBrandCategoryList(this.wardId)
            this.$emit('refreshList')
          }
        } catch (e) {
          this.$message.error(e)
        }
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    handleCurrentChange2 (val) {
      this.legionPage = val
      this.getBrandCategoryList(this.wardId)
    },
    handleCurrentChange3 (val) {
      this.selectlegionPage = val
      this.getBrandCategoryList(0)
    },
    // 添加战区
    addWard() {
      this.wardForm = {
        sort: 1
      }
      this.addWardVisible = true
    },
    // 添加战区
    async handleSubmit() {
      let params = {
        ...this.wardForm,
        actId: this.activityID
      }
      if (!params.name) {
        this.$message.warning("战区名称不能为空");
        return;
      }
      if (params.name && params.name.includes(' ')) {
        this.$message.warning("战区名称不能包含空格");
        return;
      }
      if (!params.sort) {
        this.$message.warning("排序值不能为空");
        return;
      }
      if (!/(^[1-9]\d*$)/.test(params.sort)) {
        this.$message.warning("排序值需为正整数");
        return;
      }
      if (params.sort > 99 || params.sort < 0) {
        this.$message.warning("排序值为1-99");
        return;
      }
      try {
        const res = await ActivityAjax.postSaveOrUpdateBrand(params);
        if (res.code === 0) {
          this.$message.success('添加成功')
          this.addWardVisible = false
          this.getList()
        }
      } catch (e) {
        this.$message.error(e)
      }
    },
  },
  created() {
    this.activityID = this.$route.params.activityID;
  }
}
</script>

<style scoped>

</style>
