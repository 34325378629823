var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "addLegion_drawer",
          attrs: { visible: _vm.addWardZone, center: "", width: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.addWardZone = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("添加战区")]),
          _c(
            "div",
            { staticClass: "select_wrap mt10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.addWard }
                },
                [_vm._v("添加战区")]
              ),
              _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: "", placeholder: "请输入战区名称" },
                    model: {
                      value: _vm.wardInfo.generalName,
                      callback: function($$v) {
                        _vm.$set(_vm.wardInfo, "generalName", $$v)
                      },
                      expression: "wardInfo.generalName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearchWard }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", icon: "el-icon-link" },
                  on: { click: _vm.handleExportWard }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.wardList, border: "", stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "战区名称" }
              }),
              _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注信息" }
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleEditWard(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-tag",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.handleSaveLeagle(scope.row)
                              }
                            }
                          },
                          [_vm._v("绑定军团")]
                        ),
                        _c(
                          "el-tag",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDeleteWard(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c("div"),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.page,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增战区",
            visible: _vm.addWardVisible,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.addWardVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.wardForm, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "战区名称", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入战区名称",
                      maxlength: "10",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.wardForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.wardForm, "name", $$v)
                      },
                      expression: "wardForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序值", required: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入排序值，数字越大排在越前" },
                    model: {
                      value: _vm.wardForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.wardForm, "sort", $$v)
                      },
                      expression: "wardForm.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注信息" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      maxlength: "100",
                      "show-word-limit": "",
                      placeholder: "备注信息"
                    },
                    model: {
                      value: _vm.wardForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.wardForm, "remark", $$v)
                      },
                      expression: "wardForm.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addWardVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "addLegion_drawer",
          attrs: { visible: _vm.showLegionVisible, center: "", width: "60%" },
          on: {
            "update:visible": function($event) {
              _vm.showLegionVisible = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("军团列表")]),
          _c(
            "div",
            { staticClass: "select_wrap mt10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: { click: _vm.selectLegion }
                },
                [_vm._v("选择军团")]
              ),
              _c(
                "div",
                [
                  _c("span", [_vm._v("请输入搜索内容:")]),
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: "", placeholder: "请输入军团ID" },
                    model: {
                      value: _vm.legionInfo.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.legionInfo, "categoryId", $$v)
                      },
                      expression: "legionInfo.categoryId"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: "", placeholder: "请输入军团名称" },
                    model: {
                      value: _vm.legionInfo.categoryName,
                      callback: function($$v) {
                        _vm.$set(_vm.legionInfo, "categoryName", $$v)
                      },
                      expression: "legionInfo.categoryName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearchLegion }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.legionList, border: "", stripe: "" }
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "军团ID" } }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "军团名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "brandCount", label: "品牌商数量" }
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDeleteWardCat(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c("div"),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.legionPage,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.legionTotal
                },
                on: { "current-change": _vm.handleCurrentChange2 }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "addLegion_drawer",
          attrs: { visible: _vm.selectLegionVisible, center: "", width: "50%" },
          on: {
            "update:visible": function($event) {
              _vm.selectLegionVisible = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("选择军团")]),
          _c("el-alert", {
            attrs: {
              title: "注意：下方列表仅显示没有归属战区的军团",
              type: "warning",
              closable: false
            }
          }),
          _c(
            "div",
            { staticClass: "select_wrap mt10" },
            [
              _c(
                "div",
                [
                  _c("span", [_vm._v("请输入搜索内容:")]),
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: "", placeholder: "请输入军团ID" },
                    model: {
                      value: _vm.selectLegionInfo.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.selectLegionInfo, "categoryId", $$v)
                      },
                      expression: "selectLegionInfo.categoryId"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: "", placeholder: "请输入军团名称" },
                    model: {
                      value: _vm.selectLegionInfo.categoryName,
                      callback: function($$v) {
                        _vm.$set(_vm.selectLegionInfo, "categoryName", $$v)
                      },
                      expression: "selectLegionInfo.categoryName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearchSelectLegion }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "brandTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.selectLegionList, border: "", stripe: "" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "60" }
              }),
              _c("el-table-column", { attrs: { prop: "id", label: "军团ID" } }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "军团名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "brandCount", label: "品牌商数量" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c("div"),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.selectlegionPage,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.selectlegionTotal
                },
                on: { "current-change": _vm.handleCurrentChange3 }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.selectLegionVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSaveLegion }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }