<template>
  <div class="BrandStore_wrapper">
    <div class="mt10">
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入品牌名称"
        v-model="BrandInfo.name"
        style="width:180px"
      ></el-input>
      <el-input
        class="ml10"
        :clearable="true"
        placeholder="请输入直播品牌商ID"
        v-model="BrandInfo.liveBrandId"
        style="width:180px"
      ></el-input>
      <el-button
        type="primary"
        class="ml10"
        icon="el-icon-search"
        @click="searchPage"
      >搜索</el-button>
      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        @click="ToSaveOrEditBrand(0)"
      >添加品牌门店</el-button>
      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        @click="handleShowLegion"
      >添加军团</el-button>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAddWard">添加战区</el-button>
      <el-button type="primary" icon="el-icon-delete" @click="SetBrandDel">批量删除</el-button>
    </div>
    <el-table
      class="brandTable"
      :data="BrandList"
      border
      stripe
      @selection-change="handleSelectionChange"
      style="width: 100%"
    >
      <el-table-column type="selection" width="60"></el-table-column>
      <el-table-column prop="name" label="品牌名称" width="180"></el-table-column>
      <el-table-column prop="brandLogo" label="品牌LOGO">
        <template slot-scope="scope">
          <img
            v-show="scope.row.brandLogo"
            :src="scope.row.brandLogo"
            style="display:block;width:50px;height:50px;margin:0 10px 0 0;"
          />
        </template>
      </el-table-column>
      <el-table-column prop="brandGeneralName" label="战区"></el-table-column>
      <el-table-column prop="brandCatName" label="军团"></el-table-column>
      <el-table-column prop="address" label="门店">
        <template slot-scope="scope">
          <el-button type="text" @click="ToShopList(scope.row,0)">查看门店信息</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column prop="liveBrandId" label="直播品牌商ID"></el-table-column>
      <el-table-column label="操作" fixed="right" width="240">
        <template slot-scope="scope">
          <el-tag @click="ToShopList(scope.row,1)">设置门店</el-tag>
          <el-tag type="success" @click="ToSaveOrEditBrand(1,scope.row)">编辑</el-tag>
          <el-tag type="danger" @click="ConfirmDelActivityBrand(scope.row.id)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="tab-page">
      <div></div>
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="BrandInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="BrandTotal"
      ></el-pagination>
    </div>

    <!-- 门店信息 -->
    <el-dialog :visible.sync="BranchDrawer" center width="1600px" class="branch_drawer">
      <p class="view_title">门店信息</p>
      <p class="view_title">品牌商：{{BrandName}}</p>
      <div class="branch_btn">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="ToSaveOrEditShop(0)"
          v-if="showShop"
        >添加门店</el-button>
        <div class="disflex ml10">
          <el-input v-model="ShopInfo.name" placeholder="请输入门店名称"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="searchPage3">搜索</el-button>
        </div>
      </div>
      <el-table :data="ShopList" border stripe style="width: 100%">
        <el-table-column prop="name" label="门店名称"></el-table-column>
        <el-table-column prop="phone" label="联系电话"></el-table-column>
        <el-table-column prop="address" label="门店地址" width="700"></el-table-column>
        <el-table-column prop="sort" label="排序" v-if="true"></el-table-column>
        <el-table-column label="操作" width="150" v-if="showShop">
          <template slot-scope="scope">
            <el-tag type="success" @click="ToSaveOrEditShop(1,scope.row)">编辑</el-tag>
            <el-tag type="danger" @click="ConfirmDelActivityShop(scope.row.id)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange3"
          :current-page="ShopInfo.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="ShopTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 添加门店 -->
    <el-dialog :title="editShopTitle" :visible.sync="branchAddDialog" width="30%">
      <!-- :rules="shopAddRules" -->
      <el-form :model="editShopForm"  label-width="100px">
        <el-form-item label="门店名称" prop="name">
          <el-input
            v-model="editShopForm.name"
            placeholder="请输入门店名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input
            v-model="editShopForm.phone"
            placeholder="请输入联系方式"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="门店地址" prop="address">
          <el-input
            v-model="editShopForm.address"
            type="textarea"
            placeholder="请输入门店地址"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model="editShopForm.sort"
            type="number"
            placeholder="请输入排序"
            maxlength="9"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="branchAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitShop">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加品牌门店 -->
    <el-drawer
      :title="editBrandTitle"
      :visible.sync="addBranchStoreDrawer"
      direction="rtl"
      size="800px"
      custom-class="addBranchStore_drawer"
    >
      <el-form :model="editBrandForm" :rules="branchAddRules" label-width="160px">
        <el-form-item label="品牌商名称" prop="name">
          <el-input
            v-model="editBrandForm.name"
            placeholder="请输入品牌商名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌商LOGO" class="up_poster" required>
          <div>
            <label for="brand-logo" class="el-upload el-upload--picture-card">
              <input
                type="file"
                ref="brandlogo"
                hidden
                id="brand-logo"
                data-type="brandLogo"
                name="upload"
                accept="image/*"
                @change="uploadImage"
              />
              <img
                v-if="editBrandForm.brandLogo"
                :src="editBrandForm.brandLogo"
                style="width:257px;height:145px;"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </label>
          </div>
          <div class="ml10 form_tips">建议尺寸750x1334px或9：16， JPG、PNG格式，限制大小2M内</div>
        </el-form-item>
        <el-form-item label="战区:" v-if="editBrandForm.brandGeneralName">{{editBrandForm.brandGeneralName}}</el-form-item>
        <el-form-item label="军团">
          <el-select v-model="editBrandForm.brandCatId" placeholder="请选择军团" class="mr10">
            <el-option
              v-for="item in NewCategory"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input
            v-model="editBrandForm.sort"
            placeholder="请输入排序值"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="直播品牌商ID绑定">
          <el-input
            v-model.number="editBrandForm.liveBrandId"
            placeholder="请输入直播品牌商ID"
            maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitBrand">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <!-- 添加军团 -->
    <el-dialog :visible.sync="addLegionDrawer" center width="1600px" class="addLegion_drawer">
      <p class="view_title">军团列表</p>
      <el-alert title="针对经销商、直营店，可根据区域进行归类" type="warning" :closable="false"></el-alert>
      <div class="select_wrap mt10">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="ToSaveOrEditCategory(0)"
        >添加军团</el-button>
        <div>
          <span>请输入搜索内容：</span>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入军团ID"
            v-model="CategoryInfo.categoryId"
          ></el-input>
        </div>
        <div>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输军团名称 "
            v-model="CategoryInfo.categoryName"
          ></el-input>
        </div>
        <div>
          <span>创建时间：</span>
          <el-date-picker
            v-model="CategoryInfo.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          class="ml10"
          icon="el-icon-search"
          @click="searchPage2"
        >搜索</el-button>
      </div>
      <el-table :data="CategoryList" border stripe style="width: 100%">
        <el-table-column prop="id" label="军团ID"></el-table-column>
        <el-table-column prop="name" label="军团名称"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="brandCount" label="品牌商数量"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" fixed="right" width="250">
          <template slot-scope="scope">
            <el-tag type="success" @click="ToSaveOrEditCategory(1,scope.row)">编辑</el-tag>
            <el-tag type="danger" @click="ConfirmDelActivityCategory(scope.row.id, scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange2"
          :current-page="CategoryInfo.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="CategoryTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 新建军团 -->
    <el-dialog :title="addArmyTitle" :visible.sync="addArmyDialog" width="30%">
      <el-form :model="addArmyForm" label-width="50px">
        <el-form-item label="名称">
          <el-input v-model="addArmyForm.name" placeholder="请输入名称" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="addArmyForm.sort" placeholder="请输入排序" maxlength="3"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addArmyDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitCategory">确 定</el-button>
      </span>
    </el-dialog>
    <add-ward ref="addWard" @refreshList="refreshList"></add-ward>
  </div>
</template>

<script>
import $ from "jquery";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";
import addWard from "./WardChildren/addWard";
export default {
  name: "BrandStore", // 品牌门店设置
  components: {addWard},
  props: {},
  data() {
    return {
      nodata: "",
      activityID: 0,
      branchViewWidth: "", // 窗体大小限制
      BranchDrawer: false, // 门店信息
      branchAddDialog: false, // 添加门店
      branchAddRuleForm: {
        name: "",
        phone: "",
        address: "",
        sort: "",
      },
      branchAddRules: {
        name: [
          { required: true, message: "请输入品牌商名称", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      shopAddRules: {
        name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        address: [
          { required: true, message: "请输入门店地址", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      addBranchStoreDrawer: false, // 添加品牌门店
      addBranchStoreForm: {
        name: "",
        poster: "",
      },
      addLegionDrawer: false, // 添加军团
      addLegionInput: "",
      addLegionInputTime: "",

      // 品牌门店所需参数
      addArmyDialog: false,
      addArmyForm: {
        actId: this.activityID,
        name: "",
        sort: 1,
      },
      addArmyTitle: "新建军团",
      editBrandTitle: "新建品牌",
      BrandList: [],
      BrandTotal: 0,
      BrandInfo: {
        actId: this.activityID,
        catId: "",
        name: "",
        page: 1,
        size: 10,
        liveBrandId:"",//直播品牌商ID
      },
      ShopList: [],
      ShopTotal: 0,
      ShopInfo: {
        actId: this.activityID,
        brandId: "",
        name: "",
        page: 1,
        size: 10,
      },
      editShopForm: {
        actId: this.activityID,
        address: "",
        brandId: "",
        name: "",
        phone: "",
        sort: 1,
      },
      editShopTitle: "",
      BrandName: "",
      NewCategory: [],
      CategoryList: [],
      CategoryTotal: 0,
      CategoryInfo: {
        actId: this.activityID,
        beginTime: "",
        categoryId: "",
        categoryName: "",
        endTime: "",
        page: 1,
        size: 10,
        timeRange: [],
      },
      editBrandForm: {
        actId: this.activityID,
        brandCatId: "",
        brandLogo: "",
        liveBrandId: "",
        name: "",
        brandGeneralName: '',
        sort: 1,
      },
      typeOptions: [
        { value: "act", label: "活动负责人" },
        { value: "brand", label: "品牌负责人" },
        { value: "staff", label: "导购员" },
        // { value: "finance", label: "财务负责人" },
      ],
      multipleSelection: [],
      showShop: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 打开添加军团
    handleShowLegion () {
      this.addLegionDrawer = true
      this.CategoryInfo.page = 1
      this.CategoryList = []
      this.CategoryTotal = 0
      this.getBrandCategoryList()
    },
    refreshList() {
      this.searchPage()
    },
    handleAddWard () {
      this.$refs.addWard.addWardZone = true
    },
    delConfirm() {
      this.$message.success("删除成功");
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 2,
        });
        this.editBrandForm.brandLogo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 品牌列表页码请求
    handleCurrentChange(val) {
      this.BrandInfo.page = val;
      this.getActivityBrandList();
    },
    searchPage() {
      this.BrandInfo.page = 1;
      this.getActivityBrandList();
    },
    // 军团列表页码请求
    handleCurrentChange2(val) {
      this.CategoryInfo.page = val;
      this.getBrandCategoryList();
    },
    searchPage2() {
      this.CategoryInfo.page = 1;
      this.getBrandCategoryList();
    },
    // 门店列表页码请求
    handleCurrentChange3(val) {
      this.ShopInfo.page = val;
      this.getBrandShopList();
    },
    searchPage3() {
      this.ShopInfo.page = 1;
      this.getBrandShopList();
    },
    // 品牌列表，根据活动id、类目id、品牌名称查询
    async getActivityBrandList() {
      try {
        this.BrandInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await ActivityAjax.getActivityBrandList(this.BrandInfo);
        this.BrandList = list;
        this.BrandTotal = total;
        console.log(this.BrandList);
        // 7.13暂无数据，需完成门店设置接口联调
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 门店列表
    async getBrandShopList() {
      try {
        this.ShopInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await ActivityAjax.getBrandShopList(this.ShopInfo);
        this.ShopList = list;
        this.ShopTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 军团列表
    async getBrandCategoryList() {
      try {
        this.CategoryInfo.actId = this.activityID;
        let act = this.CategoryInfo;
        if (act.timeRange && act.timeRange.length > 0) {
          act.beginTime = act.timeRange[0];
          act.endTime = act.timeRange[1];
          // delete act.timeRange;
        } else {
          act.beginTime = "";
          act.endTime = "";
        }
        act.size = 10
        const {data: { list, total }} = await ActivityAjax.getBrandCategoryList(act);
        this.CategoryList = list;
        this.CategoryTotal = total;
        if (this.CategoryList.length === 0 && this.CategoryInfo.page > 1) {
          this.CategoryInfo.page--
          this.getBrandCategoryList()
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 进入军团编辑或新增
    ToSaveOrEditCategory(type, row) {
      this.addArmyTitle = "新建军团";
      this.addArmyForm = {
        actId: this.activityID,
        name: "",
        sort: 1,
      };
      if (type == 1) {
        this.addArmyForm.id = row.id;
        this.addArmyTitle = "编辑军团";
        this.addArmyForm.name = row.name;
        this.addArmyForm.sort = Number(row.sort);
      }
      this.addArmyDialog = true;
    },
    // 进入品牌门店列表
    async ToShopList(row, type) {
      try {
        if (type) {
          this.showShop = true;
        } else {
          this.showShop = false;
        }
        this.addArmyTitle = "新建军团";
        this.ShopInfo = {
          actId: 0,
          brandId: row.id,
          name: "",
          page: 1,
          size: 10,
        };
        this.BrandName = row.name;
        await this.getBrandShopList();
        this.BranchDrawer = true;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 进入品牌编辑或新增抽屉
    async ToSaveOrEditBrand(type, row) {
      this.editBrandTitle = "新建品牌";
      this.editBrandForm = {
        actId: this.activityID,
        brandCatId: "",
        brandLogo: "",
        liveBrandId: "",
        name: "",
        sort: 1,
      };
      if (type == 1) {
        this.editBrandForm.id = row.id;
        this.editBrandForm.name = row.name;
        this.editBrandForm.brandLogo = row.brandLogo;
        this.editBrandForm.brandGeneralName = row.brandGeneralName;
        this.editBrandForm.brandCatId = row.brandCatId ? Number(row.brandCatId) : "";
        this.editBrandForm.liveBrandId = row.liveBrandId ? Number(row.liveBrandId) : "";
        this.editBrandForm.sort = Number(row.sort);
        this.editBrandTitle = "编辑品牌1";
      }
      await this.getNewCategory();
      this.addBranchStoreDrawer = true;
    },
    // 进入品牌门店编辑或新增
    async ToSaveOrEditShop(type, row) {
      this.editShopTitle = "新建品牌门店";
      this.editShopForm = {
        actId: this.activityID,
        address: "",
        brandId: "",
        name: "",
        phone: "",
        sort: 1,
      };
      if (type == 1) {
        this.editShopForm.id = row.id;
        this.editShopForm.name = row.name;
        this.editShopForm.address = row.address;
        this.editShopForm.phone = row.phone;
        this.editShopForm.sort = Number(row.sort);
        this.editShopForm.brandId = Number(row.brandId);
        this.editShopTitle = "编辑品牌门店";
      }
      this.branchAddDialog = true;
    },
    // 确认新建品牌门店
    async submitShop() {
      this.editShopForm.actId = this.activityID;
      this.editShopForm.brandId = this.ShopInfo.brandId;
      let act = JSON.parse(JSON.stringify(this.editShopForm));
      // 验证数据
      if (this.isNullOrEmpty(act.name)) {
        this.$message.warning("门店名称不能为空");
        return;
      }
      // if(act.phone && act.phone.includes(' ')) {
      //   this.$message.warning("联系方式不能包含空格");
      //   return
      // }
      // if (act.phone && !/^[1][3,4,5,7,8][0-9]{9}$/.test(act.phone)) {
      //   this.$message.warning("请输入合法的联系方式");
      //   return
      // }
      if (this.isNullOrEmpty(act.phone)) {
        this.$message.warning("联系方式不能为空");
        return;
      }
      if (this.isNullOrEmpty(act.address)) {
        this.$message.warning("门店地址不能为空");
        return;
      }
      if (this.isNullOrEmpty(act.sort)) {
        this.$message.warning("排序值不能为空");
        return;
      }
      if (!/(^[1-9]\d*$)/.test(act.sort)) {
        this.$message.warning("排序值需为正整数");
        return;
      }
      if (act.sort > 99 || act.sort < 0) {
        this.$message.warning("排序值为1-99");
        return;
      }

      console.log(act);
      await ActivityAjax.SaveBrandShop(act);
      if (this.editShopForm.id) {
        this.$message.success("编辑成功");
      } else {
        this.$message.success("新建成功");
      }
      this.ShopInfo.page = 1;
      await this.getBrandShopList();
      this.branchAddDialog = false;
    },
    // 确认新建品牌
    async submitBrand() {
      try {
        this.editBrandForm.actId = Number(this.activityID);
        let act = JSON.parse(JSON.stringify(this.editBrandForm));
        // 验证数据
        if (this.isNullOrEmpty(act.name)) {
          this.$message.warning("品牌商名称不能为空");
          return;
        }
        if (this.isNullOrEmpty(act.brandLogo)) {
          this.$message.warning("品牌商LOGO不能为空");
          return;
        }
        if (this.isNullOrEmpty(act.sort)) {
          this.$message.warning("排序值不能为空");
          return;
        }
        if (!/(^[1-9]\d*$)/.test(act.sort)) {
          this.$message.warning("排序值需为正整数");
          return;
        }
        if (act.sort > 99 || act.sort < 0) {
          this.$message.warning("排序值为1-99");
          return;
        }
        // if (this.isNullOrEmpty(act.liveBrandId)) {
        //   this.$message.warning("直播品牌商ID不能为空");
        //   return;
        // }
        if (act.liveBrandId && Number(act.liveBrandId) <= 0) {
          this.$message.warning("直播品牌商ID需为正整数");
          return;
        }
        console.log(act);
        await ActivityAjax.SaveActivityBrand(act);
        if (this.editBrandForm.id) {
          this.$message.success("编辑成功");
        } else {
          this.$message.success("新建成功");
        }
        await this.getActivityBrandList();
        this.addBranchStoreDrawer = false;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 确认新建军团
    async submitCategory() {
      this.addArmyForm.actId = this.activityID;
      let act = JSON.parse(JSON.stringify(this.addArmyForm));
      // 验证数据
      if (this.isNullOrEmpty(act.name)) {
        this.$message.warning("军团名不能为空");
        return;
      }
      if (this.isNullOrEmpty(act.sort)) {
        this.$message.warning("排序值不能为空");
        return;
      }
      if (!/(^[1-9]\d*$)/.test(act.sort)) {
        this.$message.warning("排序值需为正整数");
        return;
      }
      if (act.sort > 99 || act.sort < 0) {
        this.$message.warning("排序值为1-99");
        return;
      }

      await ActivityAjax.SaveActivityCategory(act);
      if (this.addArmyForm.id) {
        this.$message.success("编辑成功");
      } else {
        this.$message.success("新建成功");
      }
      await this.getBrandCategoryList();
      this.addArmyDialog = false;
    },
    // 删除军团
    ConfirmDelActivityCategory(Id, item) {
      let str = item.brandCount > 0 ? '该军团已经绑定品牌了，确实删除吗？' : '删除军团后将不可恢复，确定删除吗？'
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delActivityCategory(Id);
        })
        .catch(() => {});
    },
    // 删除品牌
    ConfirmDelActivityBrand(Id) {
      var self = this;
      this.$confirm("删除品牌商后将不可恢复，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delActivityBrand(Id);
        })
        .catch(() => {});
    },
    // 删除品牌门店
    ConfirmDelActivityShop(Id) {
      var self = this;
      this.$confirm("删除品牌门店后将不可恢复，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delActivityShop(Id);
        })
        .catch(() => {});
    },
    async delActivityBrand(Id) {
      await ActivityAjax.delActivityBrand(Id);
      this.BrandInfo.page = 1;
      await this.getActivityBrandList();
      this.$message.success("删除成功");
    },
    async delActivityCategory(Id) {
      await ActivityAjax.delActivityCategory(Id);
      await this.getBrandCategoryList();
      this.$message.success("删除成功");
    },
    async delActivityShop(Id) {
      await ActivityAjax.delActivityShop(Id);
      this.ShopInfo.page = 1;
      await this.getBrandShopList();
      this.$message.success("删除成功");
    },
    // 品牌列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除品牌
    SetBrandDel() {
      var self = this;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm("删除品牌商后将不可恢复，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection.length; i++) {
            var item = self.multipleSelection[i];
            if (item) {
              ids.push(item.id);
            }
          }
          var query = ids.toString();
          this.delActivityBrand(query);
        })
        .catch(() => {});
    },
    // 获取最新的军团列表
    async getNewCategory() {
      try {
        let params = {
          actId: 0,
          beginTime: "",
          categoryId: "",
          categoryName: "",
          endTime: "",
          page: 1,
          size: 1000,
        };
        params.actId = this.activityID;
        const {
          data: { list },
        } = await ActivityAjax.getBrandCategoryList(params);
        this.NewCategory = list;
        console.log(this.NewCategory);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    console.log(this.$route.params.activityID);
    this.activityID = this.$route.params.activityID;
    this.getActivityBrandList();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>

<style lang="scss" >
.BrandStore_wrapper {
  .up_poster {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-upload--text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 178px;
        height: 178px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
  .brandTable {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  // 门店信息
  .branch_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .Brand_Owner {
      padding: 10px 0;
    }
    .branch_btn {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 20px;
      .el-input {
        margin-right: 10px;
      }
    }
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .el-upload--picture-card {
    width: 260px;
    height: 148px;
  }
  // 添加品牌门店
  .addBranchStore_drawer {
    .el-drawer__body {
      padding-right: 20px;
      .el-form-item__content {
        display: flex;
        align-items: center;
        .el-upload--text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 500px;
          height: 178px;
        }
        .form_tips {
          color: #888;
          font-size: 12px;
        }
      }
    }
  }
  // 添加军团
  .addLegion_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .select_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      div {
        height: 40px;
        line-height: 40px;
        margin: 0 10px;
        .el-select {
          width: 150px !important;
        }
        .el-input {
          width: 200px;
        }
      }
    }
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
