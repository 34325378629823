var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BrandStore_wrapper" },
    [
      _c(
        "div",
        { staticClass: "mt10" },
        [
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入品牌名称" },
            model: {
              value: _vm.BrandInfo.name,
              callback: function($$v) {
                _vm.$set(_vm.BrandInfo, "name", $$v)
              },
              expression: "BrandInfo.name"
            }
          }),
          _c("el-input", {
            staticClass: "ml10",
            staticStyle: { width: "180px" },
            attrs: { clearable: true, placeholder: "请输入直播品牌商ID" },
            model: {
              value: _vm.BrandInfo.liveBrandId,
              callback: function($$v) {
                _vm.$set(_vm.BrandInfo, "liveBrandId", $$v)
              },
              expression: "BrandInfo.liveBrandId"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
              on: {
                click: function($event) {
                  return _vm.ToSaveOrEditBrand(0)
                }
              }
            },
            [_vm._v("添加品牌门店")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
              on: { click: _vm.handleShowLegion }
            },
            [_vm._v("添加军团")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
              on: { click: _vm.handleAddWard }
            },
            [_vm._v("添加战区")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-delete" },
              on: { click: _vm.SetBrandDel }
            },
            [_vm._v("批量删除")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "brandTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.BrandList, border: "", stripe: "" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "60" } }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "品牌名称", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "brandLogo", label: "品牌LOGO" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: scope.row.brandLogo,
                          expression: "scope.row.brandLogo"
                        }
                      ],
                      staticStyle: {
                        display: "block",
                        width: "50px",
                        height: "50px",
                        margin: "0 10px 0 0"
                      },
                      attrs: { src: scope.row.brandLogo }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "brandGeneralName", label: "战区" }
          }),
          _c("el-table-column", {
            attrs: { prop: "brandCatName", label: "军团" }
          }),
          _c("el-table-column", {
            attrs: { prop: "address", label: "门店" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.ToShopList(scope.row, 0)
                          }
                        }
                      },
                      [_vm._v("查看门店信息")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
          _c("el-table-column", {
            attrs: { prop: "liveBrandId", label: "直播品牌商ID" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", fixed: "right", width: "240" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        on: {
                          click: function($event) {
                            return _vm.ToShopList(scope.row, 1)
                          }
                        }
                      },
                      [_vm._v("设置门店")]
                    ),
                    _c(
                      "el-tag",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function($event) {
                            return _vm.ToSaveOrEditBrand(1, scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-tag",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.ConfirmDelActivityBrand(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("div"),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.BrandInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.BrandTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "branch_drawer",
          attrs: { visible: _vm.BranchDrawer, center: "", width: "1600px" },
          on: {
            "update:visible": function($event) {
              _vm.BranchDrawer = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("门店信息")]),
          _c("p", { staticClass: "view_title" }, [
            _vm._v("品牌商：" + _vm._s(_vm.BrandName))
          ]),
          _c(
            "div",
            { staticClass: "branch_btn" },
            [
              _vm.showShop
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.ToSaveOrEditShop(0)
                        }
                      }
                    },
                    [_vm._v("添加门店")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "disflex ml10" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门店名称" },
                    model: {
                      value: _vm.ShopInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.ShopInfo, "name", $$v)
                      },
                      expression: "ShopInfo.name"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage3 }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.ShopList, border: "", stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "门店名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "联系电话" }
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "门店地址", width: "700" }
              }),
              true
                ? _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" }
                  })
                : _vm._e(),
              _vm.showShop
                ? _c("el-table-column", {
                    attrs: { label: "操作", width: "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: { type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.ToSaveOrEditShop(1, scope.row)
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-tag",
                                {
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.ConfirmDelActivityShop(
                                        scope.row.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3129369080
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c("div"),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.ShopInfo.page,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.ShopTotal
                },
                on: { "current-change": _vm.handleCurrentChange3 }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editShopTitle,
            visible: _vm.branchAddDialog,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.branchAddDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.editShopForm, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "门店名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入门店名称",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editShopForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.editShopForm, "name", $$v)
                      },
                      expression: "editShopForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入联系方式",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editShopForm.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.editShopForm, "phone", $$v)
                      },
                      expression: "editShopForm.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入门店地址",
                      maxlength: "50",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editShopForm.address,
                      callback: function($$v) {
                        _vm.$set(_vm.editShopForm, "address", $$v)
                      },
                      expression: "editShopForm.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: "请输入排序",
                      maxlength: "9",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editShopForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.editShopForm, "sort", $$v)
                      },
                      expression: "editShopForm.sort"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.branchAddDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitShop } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.editBrandTitle,
            visible: _vm.addBranchStoreDrawer,
            direction: "rtl",
            size: "800px",
            "custom-class": "addBranchStore_drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.addBranchStoreDrawer = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.editBrandForm,
                rules: _vm.branchAddRules,
                "label-width": "160px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "品牌商名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入品牌商名称",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editBrandForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.editBrandForm, "name", $$v)
                      },
                      expression: "editBrandForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "up_poster",
                  attrs: { label: "品牌商LOGO", required: "" }
                },
                [
                  _c("div", [
                    _c(
                      "label",
                      {
                        staticClass: "el-upload el-upload--picture-card",
                        attrs: { for: "brand-logo" }
                      },
                      [
                        _c("input", {
                          ref: "brandlogo",
                          attrs: {
                            type: "file",
                            hidden: "",
                            id: "brand-logo",
                            "data-type": "brandLogo",
                            name: "upload",
                            accept: "image/*"
                          },
                          on: { change: _vm.uploadImage }
                        }),
                        _vm.editBrandForm.brandLogo
                          ? _c("img", {
                              staticStyle: { width: "257px", height: "145px" },
                              attrs: { src: _vm.editBrandForm.brandLogo }
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon"
                            })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "ml10 form_tips" }, [
                    _vm._v(
                      "建议尺寸750x1334px或9：16， JPG、PNG格式，限制大小2M内"
                    )
                  ])
                ]
              ),
              _vm.editBrandForm.brandGeneralName
                ? _c("el-form-item", { attrs: { label: "战区:" } }, [
                    _vm._v(_vm._s(_vm.editBrandForm.brandGeneralName))
                  ])
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "军团" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mr10",
                      attrs: { placeholder: "请选择军团" },
                      model: {
                        value: _vm.editBrandForm.brandCatId,
                        callback: function($$v) {
                          _vm.$set(_vm.editBrandForm, "brandCatId", $$v)
                        },
                        expression: "editBrandForm.brandCatId"
                      }
                    },
                    _vm._l(_vm.NewCategory, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序值", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入排序值",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editBrandForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.editBrandForm, "sort", $$v)
                      },
                      expression: "editBrandForm.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "直播品牌商ID绑定" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入直播品牌商ID",
                      maxlength: "6"
                    },
                    model: {
                      value: _vm.editBrandForm.liveBrandId,
                      callback: function($$v) {
                        _vm.$set(_vm.editBrandForm, "liveBrandId", _vm._n($$v))
                      },
                      expression: "editBrandForm.liveBrandId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitBrand }
                    },
                    [_vm._v("保 存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "addLegion_drawer",
          attrs: { visible: _vm.addLegionDrawer, center: "", width: "1600px" },
          on: {
            "update:visible": function($event) {
              _vm.addLegionDrawer = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("军团列表")]),
          _c("el-alert", {
            attrs: {
              title: "针对经销商、直营店，可根据区域进行归类",
              type: "warning",
              closable: false
            }
          }),
          _c(
            "div",
            { staticClass: "select_wrap mt10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.ToSaveOrEditCategory(0)
                    }
                  }
                },
                [_vm._v("添加军团")]
              ),
              _c(
                "div",
                [
                  _c("span", [_vm._v("请输入搜索内容：")]),
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: true, placeholder: "请输入军团ID" },
                    model: {
                      value: _vm.CategoryInfo.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.CategoryInfo, "categoryId", $$v)
                      },
                      expression: "CategoryInfo.categoryId"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "ml10",
                    attrs: { clearable: true, placeholder: "请输军团名称 " },
                    model: {
                      value: _vm.CategoryInfo.categoryName,
                      callback: function($$v) {
                        _vm.$set(_vm.CategoryInfo, "categoryName", $$v)
                      },
                      expression: "CategoryInfo.categoryName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("span", [_vm._v("创建时间：")]),
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.CategoryInfo.timeRange,
                      callback: function($$v) {
                        _vm.$set(_vm.CategoryInfo, "timeRange", $$v)
                      },
                      expression: "CategoryInfo.timeRange"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.searchPage2 }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.CategoryList, border: "", stripe: "" }
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "军团ID" } }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "军团名称" }
              }),
              _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
              _c("el-table-column", {
                attrs: { prop: "brandCount", label: "品牌商数量" }
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.ToSaveOrEditCategory(1, scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-tag",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.ConfirmDelActivityCategory(
                                  scope.row.id,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c("div"),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.CategoryInfo.page,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.CategoryTotal
                },
                on: { "current-change": _vm.handleCurrentChange2 }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addArmyTitle,
            visible: _vm.addArmyDialog,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.addArmyDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.addArmyForm, "label-width": "50px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入名称",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.addArmyForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.addArmyForm, "name", $$v)
                      },
                      expression: "addArmyForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入排序", maxlength: "3" },
                    model: {
                      value: _vm.addArmyForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.addArmyForm, "sort", $$v)
                      },
                      expression: "addArmyForm.sort"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addArmyDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitCategory }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("add-ward", { ref: "addWard", on: { refreshList: _vm.refreshList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }